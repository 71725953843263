import { useContext, useEffect, useState } from "react";
import { Accordion, AccordionSummary, AccordionDetails, Alert, Box, Button, Grid, List, ListItem, ListItemText, TextField, Typography } from "@mui/material"
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from "@mui/material/IconButton";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { addColumnSynonym, deleteColumnSynonymByName } from "../../../utils/api";
import ProcessFileContext from "../../../contexts/processFileContext";
import { useSnackbar } from 'notistack'

const ColumnSynonimContent = ({ projectId }) => {
    const { missingColumns, foundColumns, identifyAccounts } = useContext(ProcessFileContext)
    const [formValues, setFormValues] = useState([])
    const { enqueueSnackbar } = useSnackbar()
    const disableSubmit = formValues.some(({ columnSynonim }) => !columnSynonim)
    const hasFoundColumns = Object.entries(foundColumns).length

    const handleSubmit = () => {
        const payload = formValues.map(({ column, columnSynonim }) => ({ column_id: column.id, description: columnSynonim, project_id: projectId }))

        addColumnSynonym(payload).then(response => {
            identifyAccounts(projectId)
            enqueueSnackbar({ message: "Sinônimo adicionado com sucesso!", variant: "success" })
        }).catch(({ response }) => {
            const { detail } = response.data
            enqueueSnackbar({ message: detail, variant: "error" })
        })
    }

    const handleChange = (evt, index) => {
        const { value } = evt.target

        const newFormValues = [...formValues]
        newFormValues[index] = { ...newFormValues[index], columnSynonim: value }

        setFormValues(newFormValues)
    }

    const handleDeleteSynonim = (synonim) => {
        deleteColumnSynonymByName(projectId, synonim).then(response => {
            enqueueSnackbar({ message: "Sinônimo excluído com sucesso!", variant: "success" })
            identifyAccounts(projectId)
        }).catch(({ response }) => {
            const { detail } = response.data
            enqueueSnackbar({ message: detail, variant: "error" })
        })
    }

    useEffect(() => {
        setFormValues(missingColumns.map(missingColumn => ({ column: missingColumn, columnSynonim: "" })))
    }, [missingColumns])

    return (
        <Grid container justify="center" direction="column" gap={4} sx={{ width: "800px" }}>
            <Typography variant="h5"><strong>Sinônimos de colunas</strong></Typography>
            {formValues.length ?
                <>
                    <Typography variant="body1">Sinônimos de colunas não encontrados:</Typography>
                    {formValues.map(({ column, columnSynonim }, index) => (
                        <Grid container item gap={4} wrap="nowrap" key={column.id}>
                            <TextField
                                name="column"
                                label="Coluna"
                                variant="standard"
                                value={column.name}
                                disabled
                                style={{ width: '100%' }}
                            />
                            <TextField
                                name="columnSynonim"
                                label="Sinônimo"
                                variant="standard"
                                value={columnSynonim}
                                style={{ width: '100%' }}
                                onChange={(evt) => handleChange(evt, index)}
                            />
                        </Grid>
                    ))}
                </>
                : null
            }
            {formValues.length ?
                <>
                    <Button variant="contained" disabled={disableSubmit} onClick={handleSubmit}>Adicionar</Button>
                </>
                : null}
            {hasFoundColumns ?
                <>
                    <Accordion elevation={3}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                        >
                            <Typography variant="body1"><strong>Sinônimos de colunas encontrados</strong></Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            {Object.entries(foundColumns).map(([column, synonim]) => (
                                <Grid container item gap={4} wrap="nowrap" key={column}>
                                    <TextField
                                        label="Coluna"
                                        variant="standard"
                                        value={column}
                                        disabled
                                        style={{ width: '100%' }}
                                    />
                                    <Box my={4} />
                                    <TextField
                                        label="Sinônimo"
                                        variant="standard"
                                        value={synonim}
                                        disabled
                                        style={{ width: '100%' }}
                                    />
                                    <IconButton aria-label="delete" onClick={() => handleDeleteSynonim(synonim)}>
                                        <DeleteIcon style={{ color: "#4c4c4c" }} />
                                    </IconButton>
                                </Grid>
                            ))}
                        </AccordionDetails>
                    </Accordion>
                </>
                : null
            }
            <>
                <Accordion elevation={3}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                    >
                        <Typography variant="body1"><strong>Orientações sobre o processamento</strong></Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <List dense={true}>
                            <ListItem>
                                <ListItemText>
                                    <Alert variant="outlined" severity="info">
                                        <Typography variant="body1">Os valores da coluna referente ao MES devem conter apenas o mês podendo ser um número(1 - 12) ou texto(janeiro - dezembro). O processamento pode ser comprometido caso a coluna de MES contenha outros tipos de dados além do mês.</Typography>
                                    </Alert>
                                </ListItemText>
                            </ListItem>
                            <ListItem>
                                <ListItemText>
                                    <Alert variant="outlined" severity="info">
                                        <Typography variant="body1">A coluna referente ao MONTANTE deve estar formatada como número</Typography>
                                    </Alert>
                                </ListItemText>
                            </ListItem>
                            <ListItem>
                                <ListItemText>
                                    <Alert variant="outlined" severity="info">
                                        <Typography variant="body1">Verificar os nomes das colunas, o texto adicionado deve ser idêntico ao texto da planilha, recomendamos que o usuário copie e cole o nome da coluna no campo de adição de sinônimo.</Typography>
                                    </Alert>
                                </ListItemText>
                            </ListItem>
                            <ListItem>
                                <ListItemText>
                                    <Alert variant="outlined" severity="info">
                                        <Typography variant="body1">Atualmente o sistema não faz distinção de ano nas planilhas, para arquivos que contenham dados de mais de um ano é necessário criar uma planilha para cada ano e processá-las separadamente.</Typography>
                                    </Alert>
                                </ListItemText>
                            </ListItem>
                        </List>
                    </AccordionDetails>
                </Accordion>
            </>
        </Grid>
    )
}

export default ColumnSynonimContent