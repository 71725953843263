import React from 'react';
import ReactDOM from 'react-dom/client';
import Router from './Router';
import './index.scss'
import { StepperContextProvider } from './contexts/stepperContext';
import { ProcessFileContextProvider } from './contexts/processFileContext';
import { SnackbarProvider } from 'notistack'

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <StepperContextProvider>
        <ProcessFileContextProvider>
            <SnackbarProvider anchorOrigin={{ horizontal: "right", vertical: 'bottom' }}>
                <Router />
            </SnackbarProvider>
        </ProcessFileContextProvider>
    </StepperContextProvider>
);
