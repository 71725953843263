import React, { createContext, useState } from 'react';
import { identifyAccounts as identifyAccountsAPI } from '../utils/api';

const ProcessFileContext = createContext();

export const ProcessFileContextProvider = ({ children }) => {
    const [uploadedFilePath, setUploadedFilePath] = useState("")
    const [processingSteps, setProcessingSteps] = useState(["PROPOSE_STEP"])
    const [missingColumns, setMissingColumns] = useState([])
    const [foundColumns, setFoundColumns] = useState([])
    const [identifiedAccounts, setIdentifiedAccounts] = useState([])
    const [suggestedAccounts, setSuggestedAccounts] = useState({})
    const [errors, setErrors] = useState([])
    const [processedFilename, setProcessedFilename] = useState("")
    const [responseFileURL, setResponseFileURL] = useState("")
    const [isLoading, setIsLoading] = useState(false)
    const [willEditLedgerAccount, setWillEditLedgerAccount] = useState("n")

    const identifyAccounts = (projectId) => {
        setIsLoading(true)
        identifyAccountsAPI(uploadedFilePath, processingSteps, projectId)
            .then(({ data }) => {
                const { missing_columns, found_columns, identified_accounts, suggested_accounts, errors } = data
                setMissingColumns(missing_columns)
                setFoundColumns(found_columns)
                setIdentifiedAccounts(identified_accounts)
                setSuggestedAccounts(suggested_accounts)
                setErrors(errors)
                setIsLoading(false)
            })
            .catch(err => {
                console.log(err)
                setIsLoading(false)
            })
    }

    return (
        <ProcessFileContext.Provider value={{ uploadedFilePath, setUploadedFilePath, processingSteps, setProcessingSteps, missingColumns, setMissingColumns, foundColumns, setFoundColumns, identifyAccounts, identifiedAccounts, suggestedAccounts, setSuggestedAccounts, errors, setErrors, setProcessedFilename, setResponseFileURL, responseFileURL, processedFilename, isLoading, setIsLoading, willEditLedgerAccount, setWillEditLedgerAccount }}>
            {children}
        </ProcessFileContext.Provider>
    );
};

export default ProcessFileContext;