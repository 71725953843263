import React from "react";
import { Grid, Button, Divider } from '@mui/material'
import { useNavigate } from "react-router-dom";
import './main-layout.scss'
import { useAuth } from '../contexts/authContext';
import { isAdmin } from "../utils/userData";

function MainLayout({ children }) {
    const { logout } = useAuth();
    const navigate = useNavigate();

    const handleClick = (ref) => {
        navigate(ref)
    }

    const handleLogout = async () => {
        try {
            await logout();
            navigate('/login');
        } catch (error) {
            console.log("error", error);
        }
    };

    return (
        <Grid container className="main-container" justifyContent="center">
            <Grid container item xs={12} className="main-container__header" justifyContent="center">
                <Button style={{ textTransform: 'none' }} onClick={() => handleClick('/')}>Clientes</Button>
                <Divider orientation="vertical" style={{ height: "30px", marginTop: "15px" }} />
                {isAdmin() && (
                    <>
                        <Button style={{ textTransform: 'none' }} onClick={() => handleClick('/users')}>Usuários</Button>
                        <Divider orientation="vertical" style={{ height: "30px", marginTop: "15px" }} />
                    </>
                )}
                <Button style={{ textTransform: 'none' }} onClick={() => handleLogout()}>Sair</Button>
            </Grid>
            <Grid container alignItems="center" justifyContent="center" item xs={10} className="main-container__content">
                {children}
            </Grid>
        </Grid >
    )
}

export default MainLayout