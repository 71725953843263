import { Avatar, Box, Chip, Divider, Grid, Paper, Typography } from "@mui/material"
import MoreOptionsButton from "../../components/MoreOptionsButton"

const task_status_enum = {
    "TO_PROCESS": "TO_PROCESS",
    "PROCESSING": "PROCESSING",
    "COMPLETED": "COMPLETED",
    "FAILED": "FAILED"
}

const StatusBadge = ({ status }) => {
    return (
        <>
            {status === task_status_enum["COMPLETED"] &&
                <Chip label="Processado" color="success" />
            }

            {status === task_status_enum["PROCESSING"] &&
                <>
                    <Chip label="Processando" color="primary" />
                    <Box mr={2} />
                </>
            }

            {status === task_status_enum["TO_PROCESS"] &&
                <>
                    <Chip label="Na fila" sx={{ backgroundColor: "#d9d9d9" }} />
                    <Box mr={2} />
                </>
            }

            {status === task_status_enum["FAILED"] &&
                <>
                    <Chip label="Falhou" color="error" />
                    <Box mr={2} />
                </>
            }
        </>
    )
}

const FileCard = ({ processedFile, file }) => {
    const handleDownloadClick = async () => {
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = file.file_link;
        a.download = file.file_name;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    }

    const handleDeleteClick = () => {
        console.log("excluir")
    }

    return (
        <Paper elevation={2} sx={{
            width: "100%", height: "70px"
        }}>
            <Grid container item alignItems="center" sx={{ height: "100%" }} wrap="nowrap">
                <Grid container item alignItems="center" >
                    <Avatar variant="square" sx={{
                        height: "100%", width: "70px", backgroundColor: "#fff", borderTopLeftRadius: "4px", borderBottomLeftRadius: "4px", "& img": {
                            height: "60%",
                            width: "60%"
                        }
                    }} src="/excel-icon.svg" />
                    <Divider orientation="vertical" flexItem />
                    <Box mx={1} />
                    {processedFile ?
                        <>
                            <Typography variant="body1">{file.file_name}</Typography>
                            <Box mx={1} />
                        </>
                        :
                        <Typography variant="body1">{file.file_name}</Typography>
                    }
                </Grid>
                <Grid container alignItems="center" sx={{ width: "fit-content" }} wrap="nowrap">
                    <Divider orientation="vertical" flexItem />
                    <Box mx={1} />
                    {processedFile &&
                        <StatusBadge status={file.status} />
                    }
                    {!processedFile || file.status === task_status_enum["COMPLETED"] ?
                        <MoreOptionsButton options={[{ label: 'Baixar', func: handleDownloadClick }, { label: 'Excluir', func: handleDeleteClick }]} />
                        : null
                    }
                </Grid>
            </Grid>
        </Paper >
    )
}

const FilesContainer = ({ files }) => {
    const initialFiles = files.filter(file => file.type === "INITIAL")
    const finalFiles = files.filter(file => file.type === "FINAL")

    return (
        <Grid container sx={{ width: "100%" }} wrap="nowrap" alignItems="flex-start">
            <Grid container item xs={6} justifyContent="center" px={4}>
                <Typography variant="h6">Arquivos Carregados</Typography>
                <Grid container item direction="column" xs={12} pt={4}>
                    {initialFiles.map(file =>
                        <>
                            <FileCard file={file} />
                            <Box my={2} />
                        </>
                    )}
                </Grid>
            </Grid>
            <Divider orientation="vertical" flexItem />
            <Grid container item xs={6} justifyContent="center" px={4}>
                <Typography variant="h6">Arquivos processados</Typography>
                <Grid container item direction="column" xs={12} pt={4}>
                    {finalFiles.map(file =>
                        <>
                            <FileCard processedFile file={file} />
                            <Box my={2} />
                        </>
                    )}
                </Grid>
            </Grid>
        </Grid>
    )
}

export default FilesContainer