import { useEffect, useState } from "react";
import MainLayout from "../../layouts"
import { Box, Divider, Paper, Tabs, Tab } from '@mui/material';
import LedgerAccountsContainer from "../../containers/LedgerAccounts";
import ColumnSynonymsContainer from "../../containers/ColumnSynonyms";
import OutLedgerAccountsContainer from "../../containers/OutLedgerAccounts";
import WorkGroupsContainer from "../../containers/WorkGroups";
import FilesContainer from "../../containers/Files";
import ProcessingContainer from "../../containers/Processing";
import ProjectViewContainer from "../../containers/Project/View";
import { useLocation, useParams } from 'react-router-dom';
import { getProject } from "../../utils/api";

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
}


const ProjectPage = () => {
    const [selectedTab, setSelectedTab] = useState('info');
    const [project, setProject] = useState({ files: [] })
    const location = useLocation();
    const { clientId, projectId } = useParams();

    const handleChange = (event, newValue) => {
        setSelectedTab(newValue);
    };

    useEffect(() => {
        const { tab } = location.state || {};

        if (tab) {
            setSelectedTab(tab)
        }
    }, [location])

    const fetchData = () => {
        getProject(clientId, projectId).then(({ data }) => setProject(data)).catch(err => console.log(err))
    }

    useEffect(() => {
        fetchData()
    }, [])

    useEffect(() => {
        // Inicia o polling apenas se a aba selecionada for "files"
        if (selectedTab === "files") {
            fetchData()
            const intervalId = setInterval(() => {
                fetchData();
            }, 10000);

            // Função de limpeza para interromper o polling ao desmontar ou mudar a aba
            return () => clearInterval(intervalId);
        }
    }, [selectedTab]);

    return (
        <MainLayout>
            <Paper sx={{ width: '100%', padding: "0 48px", borderRadius: "8px" }}>
                <Tabs
                    value={selectedTab}
                    variant="scrollable"
                    scrollButtons="auto"
                    onChange={handleChange}
                >
                    <Tab value="info" label="Informações do Projeto" />
                    <Tab value="ledger-accounts" label="Contas Razão" />
                    <Tab value="column-synonyms" label="Sinônimos das Colunas" />
                    <Tab value="out-ledger-accounts" label="Contas Fora de escopo" />
                    <Tab value="work-groups" label="Grupos de Trabalho" />
                    <Tab value="processing" label="Processamento" />
                    <Tab value="files" label="arquivos" />
                </Tabs>
                <Divider sx={{
                    width: "calc(100% + 96px)",
                    marginLeft: "-48px"
                }} />
                <CustomTabPanel value={selectedTab} index={"info"}>
                    <ProjectViewContainer clientId={clientId} fetchData={fetchData} project={project} />
                </CustomTabPanel>
                <CustomTabPanel value={selectedTab} index={"ledger-accounts"}>
                    <LedgerAccountsContainer projectId={projectId} />
                </CustomTabPanel>
                <CustomTabPanel value={selectedTab} index={"column-synonyms"}>
                    <ColumnSynonymsContainer projectId={projectId} />
                </CustomTabPanel>
                <CustomTabPanel value={selectedTab} index={"out-ledger-accounts"}>
                    <OutLedgerAccountsContainer projectId={projectId} />
                </CustomTabPanel>
                <CustomTabPanel value={selectedTab} index={"work-groups"}>
                    <WorkGroupsContainer projectId={projectId} />
                </CustomTabPanel>
                <CustomTabPanel value={selectedTab} index={"processing"}>
                    <ProcessingContainer clientId={clientId} projectId={projectId} fetchData={fetchData} />
                </CustomTabPanel>
                <CustomTabPanel value={selectedTab} index={"files"}>
                    <FilesContainer files={project.files} />
                </CustomTabPanel>
            </Paper>
        </MainLayout>
    )
}

export default ProjectPage