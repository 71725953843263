import Modal from "../../BaseModal"
import { Box, Button, Grid, Typography } from "@mui/material"
import WarningIcon from '@mui/icons-material/Warning';
import { useNavigate } from "react-router-dom";
import { deleteProject } from "../../../utils/api"

const DeleteProjectModal = ({ showModal, handleClose, clientId, project }) => {
    const navigate = useNavigate()

    const handleCancelClick = () => {
        handleClose()
    }

    const handleDeleteClick = () => {
        deleteProject(clientId, project.id).then(() => {
            navigate(`/client/${clientId}`)
        }).catch(err => console.log(err))
    }

    return (
        <Modal open={showModal} handleClose={handleClose}>
            <Grid container direction="column" alignItems="center" sx={{ width: "400px" }}>
                <WarningIcon sx={{ fontSize: 100 }} color="error" />
                <Box py={2} />
                <Typography variant="h6" align="center">Você tem certeza que deseja excluir o projeto <strong>{project.name}</strong>?</Typography>
                <Box py={2} />
                <Grid container justifyContent="space-between">
                    <Button variant="outlined" sx={{ width: "45%" }} onClick={handleCancelClick}>Cancelar</Button>
                    <Button variant="contained" color="error" sx={{ width: "45%" }} onClick={handleDeleteClick}>Excluir</Button>
                </Grid>
            </Grid>
        </Modal >
    )
}

export default DeleteProjectModal