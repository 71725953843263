import { useEffect, useContext } from 'react';
import {
    Grid,
    Stepper as MUIStepper,
    Step,
    StepLabel,
    Box
} from '@mui/material';
import ProcessingContent from '../ProcessingContent';
import PreProcessingContent from '../PreProcessingContent';
import ResultsContent from '../ResultsContent';
import StepperContext from '../../../contexts/stepperContext';

function ProcessFileStepper({ clientId, projectId, fetchData }) {
    const { activeStep, labels, setLabels, contents, setContents } = useContext(StepperContext);

    useEffect(() => {
        setLabels(['Configuração', 'Pré-Processamento'])
        setContents([<ProcessingContent projectId={projectId} fetchData={fetchData} />, <PreProcessingContent clientId={clientId} projectId={projectId} />])
    }, [])

    return (
        <Grid container direction="column" alignItems="center" >
            <Grid item sx={{ width: "600px" }}>
                <MUIStepper activeStep={activeStep}>
                    {labels.map((label) => {
                        const stepProps = {};
                        const labelProps = {};

                        return (
                            <Step key={label} {...stepProps}>
                                <StepLabel {...labelProps}>{label}</StepLabel>
                            </Step>
                        );
                    })}
                </MUIStepper>
            </Grid>
            <Box mb={4} />
            {contents[activeStep]}
        </Grid>
    );
}

export default ProcessFileStepper;