import { Avatar, Box, Button, Divider, Grid, Paper, TextField, Typography } from '@mui/material'
import DriveFileMoveIcon from '@mui/icons-material/DriveFileMove';
import EditProjectModal from '../../../components/Projects/EditProjectModal';
import DeleteProjectModal from '../../../components/Projects/DeleteProjectModal';
import { useEffect, useRef, useState } from "react"

const ProjectViewContainer = ({ clientId, project, fetchData }) => {
    const [showEditProjectModal, setShowEditProjectModal] = useState(false)
    const [showDeleteProjectModal, setShowDeleteProjectModal] = useState(false)

    const handleEditProjectClick = () => {
        setShowEditProjectModal(true)
    }

    const handleDeleteProjectClick = () => {
        setShowDeleteProjectModal(true)
    }

    const handleModalClose = () => {
        setShowEditProjectModal(false)
        fetchData()
    }

    const handleDeleteProjectModalClose = () => {
        setShowDeleteProjectModal(false)
    }

    return (
        <>
            <Grid container item xs={12} my={6}>
                <Grid container direction="column" item xs={3} alignItems="center" >
                    <DriveFileMoveIcon sx={{ height: "100px", width: "100px" }} />
                    <Box my={2} />
                    <Grid container item direction="column" justifyContent="space-between">
                        <Button variant="contained" onClick={handleEditProjectClick}>Editar</Button>
                        <Box my={1} />
                        <Button variant="contained" color="error" onClick={handleDeleteProjectClick}>Excluir</Button>
                    </Grid>
                </Grid>
                <Grid container item xs={1} justifyContent="center">
                    <Divider orientation="vertical" flexItem />
                </Grid>
                {project ?
                    <Grid container item direction="column" xs={8} >
                        <Typography variant="h6"><strong>Nome</strong></Typography>
                        <Typography variant="body1">{project.name}</Typography>
                        <Box my={1} />
                        <Typography variant="h6"><strong>Descrição</strong></Typography>
                        <Typography variant="body1">{project.description}</Typography>
                    </Grid>
                    : null
                }
            </Grid>

            <EditProjectModal showModal={showEditProjectModal} handleClose={handleModalClose} clientId={clientId} project={project} />
            <DeleteProjectModal showModal={showDeleteProjectModal} handleClose={handleDeleteProjectModalClose} clientId={clientId} project={project} />
        </>

    )
}

export default ProjectViewContainer