import Modal from "../../BaseModal"
import { useForm, Controller } from 'react-hook-form';
import { Avatar, Box, Button, Grid, IconButton, Input, TextField } from "@mui/material"
import { PhotoCamera } from '@mui/icons-material';
import TextArea from "../../TextArea"
import { addCustomer } from "../../../utils/api";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

const NewClientModal = ({ showModal, handleClose }) => {
    const [avatar, setAvatar] = useState(null)
    const { handleSubmit, control, formState: { errors, isValid }, reset, } = useForm({ mode: 'onChange' });
    const navigate = useNavigate()

    const onSubmit = (data, event) => {
        if (isValid) {
            const formData = new FormData();

            Object.keys(data).forEach((key) => {
                formData.append(key, data[key]);
            });

            if (!data.file) {
                formData.delete("file")
            }

            event.preventDefault()
            event.stopPropagation()

            addCustomer(formData).then(({ data }) => {
                closeModal()
                navigate(`/client/${data.id}`)
            }).catch(err => {
                console.log(err)
            })
        }
    }

    const createAvatarThumb = (file) => {
        if (file) {
            const reader = new FileReader();
            reader.onload = () => setAvatar(reader.result);
            reader.readAsDataURL(file);
        }
    }

    const closeModal = () => {
        if (handleClose) {
            handleClose()
        }

        reset()
    }

    return (
        <Modal open={showModal} handleClose={closeModal} title="Adicionar novo cliente">
            <Grid container direction="column" sx={{ width: "600px" }}>
                <Box pt={6}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Grid container item xs={12} spacing={8}>
                            <Grid container item justifyContent="center" xs={4}>
                                <Avatar src={avatar} variant="rounded" sx={{ width: "200px", height: "200px", "& .MuiAvatar-img": { width: "100%", height: "auto" } }} />
                                <Controller
                                    name="file"
                                    control={control}
                                    defaultValue={null}
                                    render={({ field: { onChange, onBlur, ref } }) => (
                                        <label htmlFor="avatar-upload">
                                            <Input
                                                accept="image/*"
                                                type="file"
                                                id="avatar-upload"
                                                onChange={(e) => {
                                                    const file = e.target.files[0]
                                                    createAvatarThumb(file)
                                                    onChange(file); // Captura o primeiro arquivo selecionado
                                                }}
                                                onBlur={onBlur}
                                                ref={ref}
                                                sx={{ display: "none" }}
                                            />
                                            <IconButton color="primary" aria-label="upload picture" component="span">
                                                <PhotoCamera />
                                            </IconButton>
                                        </label>
                                    )}
                                />
                            </Grid>
                            <Grid container item direction="column" xs={8}>
                                <Controller
                                    name="name"
                                    control={control}
                                    defaultValue=""
                                    rules={{ required: 'O campo nome é obrigatório' }}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            label="Nome"
                                            error={!!errors.name}
                                            helperText={errors.name ? errors.name.message : ''}
                                            fullWidth
                                        />
                                    )}
                                />
                                <Box my={2} />
                                <Controller
                                    name="description"
                                    control={control}
                                    defaultValue=""
                                    rules={{ required: 'O campo descrição é obrigatório' }}
                                    render={({ field }) => (
                                        <TextArea {...field} placeholder="Descrição cliente" minRows={5} maxRows={8} error={!!errors.description}
                                            helperText={errors.description ? errors.description.message : ''} />
                                    )}
                                />
                                <Box my={2} />
                                <Button variant="contained" fullWidth type="submit">Adicionar</Button>
                                <Box my={1} />
                            </Grid>
                        </Grid>
                    </form>
                </Box>
            </Grid>
        </Modal >
    )
}

export default NewClientModal