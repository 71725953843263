import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';

export default function MoreOptionsButton({ options }) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleItemClick = (func) => {
        func()
        handleClose()
    }

    return (
        <div>
            <IconButton
                id="long-button"
                onClick={handleClick}
            >
                <MoreVertIcon />
            </IconButton>
            <Menu
                id="long-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                {options.map(({ label, func }) => (
                    <MenuItem key={label} onClick={() => handleItemClick(func)}>
                        {label}
                    </MenuItem>
                ))}
            </Menu>
        </div>
    );
}
