import * as React from 'react';
import { Typography } from "@mui/material"
import { TextareaAutosize as BaseTextareaAutosize } from '@mui/base/TextareaAutosize';
import { styled } from '@mui/system';

const blue = {
  100: '#DAECFF',
  200: '#b6daff',
  400: '#3399FF',
  500: '#007FFF',
  600: '#0072E5',
  900: '#003A75',
};

const grey = {
  50: '#F3F6F9',
  100: '#E5EAF2',
  200: '#DAE2ED',
  300: '#C7D0DD',
  400: '#B0B8C4',
  500: '#9DA8B7',
  600: '#6B7A90',
  700: '#434D5B',
  800: '#303740',
  900: '#1C2025',
};

const Textarea = styled(BaseTextareaAutosize)(
  ({ theme }) => `
  box-sizing: border-box;
  width: 100%;
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 8px 12px;
  border-radius: 4px;
  color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
  background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
  border: 1px solid ${grey[300]};
  box-shadow: 0px 2px 2px ${theme.palette.mode === 'dark' ? grey[900] : grey[50]};
  resize: none;

  &:hover {
    border-color: ${grey[900]};
  }

  &:focus {
    border-color: border-color: ${blue[500]};
    box-shadow: 0 0 0 1px ${blue[500]};
  }

  // firefox
  &:focus-visible {
    outline: 0;
  }

  &.error {
    border-color: #d32f2f;

    &::placeholder {
      color: #d32f2f;
    }

    &:focus {
      border-color: border-color: #d32f2f;
      box-shadow: 0 0 0 1px #d32f2f;
    }
  }
`,
);

export default function TextArea({ minRows = 0, maxRows, placeholder, error, helperText, value, onChange }) {
  return (
    <>
      <Textarea className={error ? "error" : null} minRows={minRows} maxRows={maxRows ? maxRows : false} placeholder={placeholder} value={value} onChange={onChange} />
      {error && helperText &&
        <Typography variant="body2" color="error" sx={{ fontSize: "12px", margin: "3px 14px 0" }}>{helperText}</Typography>
      }
    </>
  );
}