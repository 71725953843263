import Modal from "../../BaseModal"
import { Box, Button, Grid, TextField } from "@mui/material"
import TextArea from "../../TextArea"
import { useForm, Controller } from 'react-hook-form'
import { useNavigate } from "react-router-dom";
import { addProject } from "../../../utils/api";

const NewProjectModal = ({ showModal, handleClose, customer }) => {
    const { handleSubmit, control, formState: { errors, isValid }, reset, } = useForm({ mode: 'onChange' });
    const navigate = useNavigate()

    const onSubmit = (data, event) => {
        if (isValid) {
            const formData = new FormData();

            Object.keys(data).forEach((key) => {
                formData.append(key, data[key]);
            });

            event.preventDefault()
            event.stopPropagation()

            addProject(customer.id, formData).then(({ data }) => {
                closeModal()
                navigate(`/client/${customer.id}/project/${data.id}`)
            }).catch(err => {
                console.log(err)
            })
        }
    }

    const closeModal = () => {
        if (handleClose) {
            handleClose()
        }

        reset()
    }

    return (
        <Modal open={showModal} handleClose={handleClose} title="Adicionar novo projeto">
            <Grid container direction="column" sx={{ width: "400px" }}>
                <Box pt={6}>
                    <Grid container item xs={12} spacing={8}>
                        <Grid container item direction="column" xs={12} >
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <Controller
                                    name="name"
                                    control={control}
                                    defaultValue=""
                                    rules={{ required: 'O campo nome é obrigatório' }}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            label="Nome"
                                            error={!!errors.name}
                                            helperText={errors.name ? errors.name.message : ''}
                                            fullWidth
                                        />
                                    )}
                                />
                                <Box my={2} />
                                <Controller
                                    name="description"
                                    control={control}
                                    defaultValue=""
                                    rules={{ required: 'O campo descrição é obrigatório' }}
                                    render={({ field }) => (
                                        <TextArea {...field} placeholder="Descrição projeto" minRows={5} maxRows={8} error={!!errors.description}
                                            helperText={errors.description ? errors.description.message : ''} />
                                    )}
                                />
                                <Box my={2} />
                                <Button variant="contained" fullWidth type="submit">Adicionar</Button>
                            </form>
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
        </Modal >
    )
}

export default NewProjectModal