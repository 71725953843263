import React, { useState } from "react";
import Grid from '@mui/material/Grid'
import Typography from "@mui/material/Typography";
import Card from '@mui/material/Card';
import Box from '@mui/material/Box'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import Divider from '../Divider'
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from "@mui/material/IconButton";
import { deleteOutLedgerAccount } from "../../utils/api";
import './out-ledger-accounts-table.scss'
import { Button, Skeleton } from "@mui/material";

function OutLedgerAccountsTable({ outLedgerAccounts, fetchData, toggleModal, isLoading }) {
    const [page, setPage] = useState(0);
    const rowsPerPage = 10

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleDeleteColumn = async (id) => {
        await deleteOutLedgerAccount(id)
        fetchData()
    }

    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - outLedgerAccounts.length) : 0;


    function skeletonTable() {
        return (
            <Grid style={{ width: '80%', margin: "auto" }} alignItems="center">
                <Skeleton height={37} />
                <Skeleton height={41} />
                <Skeleton height={41} />
                <Skeleton height={41} />
                <Skeleton height={41} />
                <Skeleton height={41} />
                <Skeleton height={41} />
                <Skeleton height={41} />
                <Skeleton height={41} />
                <Skeleton height={41} />
                <Skeleton height={41} />
                <Grid container justifyContent="flex-end">
                    <Skeleton width={200} height={41} />
                </Grid>
            </Grid>
        )
    }

    return (
        <Card className="column-synonyms-table-wrapper" style={{ width: "100%" }}>
            <Grid container className="title" alignItems="center" justifyContent="flex-end">
                <Button variant="contained" onClick={toggleModal}>Adicionar Conta</Button>
            </Grid>
            <Box py={4} />
            {isLoading ?
                skeletonTable()
                :
                <>
                    <TableContainer className="__table-container">
                        <Table aria-label="simple table" size="small">
                            <TableHead className='table-header'>
                                <TableRow>
                                    <TableCell className="__table-cell"><Typography variant="body1"><strong>Conta Fora de Escopo</strong></Typography></TableCell>
                                    <TableCell className="__table-cell"><Typography variant="body1"><strong>Ações</strong></Typography></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody className="table-body">
                                {outLedgerAccounts
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((outLedgerAccount) => {
                                        return (
                                            <TableRow
                                                key={outLedgerAccount.id}
                                            >
                                                <TableCell className="__table-cell" component="th" scope="row">
                                                    {outLedgerAccount.description}
                                                </TableCell>
                                                <TableCell className="__table-cell" align="right">
                                                    <IconButton aria-label="delete" onClick={() => handleDeleteColumn(outLedgerAccount.id)}>
                                                        <DeleteIcon style={{ color: "#4c4c4c" }} />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                {emptyRows > 0 && (
                                    <TableRow style={{ height: 32.7 * emptyRows }}>
                                        <TableCell colSpan={6} style={{ borderBottom: 'none' }} />
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        className="__table-pagination"
                        rowsPerPageOptions={[]}
                        component="div"
                        count={outLedgerAccounts.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                    />
                </>}
        </Card>
    )
}

export default OutLedgerAccountsTable