import { useContext, useEffect } from "react";
import { Box, Button, Card, Divider, Grid, Skeleton, Typography } from "@mui/material"
import ColumnSynonimContent from "../ColumnSynonimContent";
import IdentifiedAccountsContent from "../IdentifiedAccountsContent";
import LedgerAccountEditionContent from "../LedgerAccountsEditionContent";
import ProcessFileContext from "../../../contexts/processFileContext";
import StepperContext from "../../../contexts/stepperContext";
import { processFile as processFileAPI } from "../../../utils/api";
import { useSnackbar } from 'notistack'
import { useNavigate } from "react-router-dom";

const PreProcessingContentSkeleton = () => {
    return (
        <>
            <Typography variant="h5"><Skeleton width={300} /></Typography>
            <Box mb={4} />
            <Typography variant="body1"><Skeleton width={800} /></Typography>
            <Box mb={4} />
            <Skeleton width={800} height={5} />
            <Box mb={4} />
            <Typography variant="h5"><Skeleton width={300} /></Typography>
            <Skeleton width={800} height={200} />
            <Box mb={4} />
            <Skeleton width={800} height={50} />
        </>
    )
}

const PreProcessingContent = ({ clientId, projectId }) => {
    const { missingColumns, suggestedAccounts, identifyAccounts, uploadedFilePath, processingSteps, setProcessedFilename, setResponseFileURL, isLoading, setIsLoading, willEditLedgerAccount, errors } = useContext(ProcessFileContext)
    const { goNext, resetStepper } = useContext(StepperContext)
    const { enqueueSnackbar } = useSnackbar()
    const navigate = useNavigate();

    const parseFilename = (filename) => {
        const regex = /filename\*\=utf-8''(.+)/;

        const match = filename.match(regex);

        if (match && match[1]) {
            const fileName = decodeURIComponent(match[1]);
            return fileName
        } else {
            return 'arquivo_processado'
        }
    }

    const processFile = (projectId) => {
        setIsLoading(true)
        processFileAPI(uploadedFilePath, processingSteps, projectId).then(response => {
            const file = new Blob([response.data], { type: response.data.type });
            const fileURL = URL.createObjectURL(file);
            setProcessedFilename(parseFilename(response.headers['content-disposition']))
            setResponseFileURL(fileURL)
            setIsLoading(false)
            enqueueSnackbar({ message: "Arquivo processado com sucesso!", variant: "success" })
        }).catch(async (error) => {
            setIsLoading(false);
            let errorMessage = 'Erro desconhecido';
            if (error.response && error.response.data) {
                // Verifica se o erro foi retornado como blob
                const data = error.response.data;
                if (data instanceof Blob) {
                    try {
                        const text = await data.text(); // Converte o blob para texto
                        const json = JSON.parse(text);  // Tenta analisar o texto como JSON
                        errorMessage = json.detail || errorMessage;
                    } catch (e) {
                        errorMessage = 'Erro ao processar o arquivo.';
                    }
                } else {
                    // Se não for um blob, tente acessar diretamente
                    errorMessage = error.response.data.detail || errorMessage;
                }
            }
            // enqueueSnackbar({ message: errorMessage, variant: "error" });
        });
    }

    const handleProcessFileClick = () => {
        if (errors.length) {
            errors.map(error => enqueueSnackbar({ message: error, variant: "error" }))
        } else {
            processFile(projectId)
            navigate(`/client/${clientId}/project/${projectId}`, { state: { tab: "files" } })
        }
    }

    useEffect(() => {
        identifyAccounts(projectId)
    }, [])

    return (
        <Grid container direction="column" alignItems="center" sx={{ mt: 2 }}>
            {
                isLoading ?
                    <PreProcessingContentSkeleton />
                    :
                    <>
                        <ColumnSynonimContent projectId={projectId} />
                        <Box mb={6} />
                        <Divider />
                        <Box mb={6} />
                        <IdentifiedAccountsContent projectId={projectId} />
                        <Box mb={6} />
                        <Divider />
                        <Box mb={6} />
                        {willEditLedgerAccount === "y" &&
                            <>
                                <LedgerAccountEditionContent projectId={projectId} />
                                <Box mb={6} />
                                <Divider />
                                <Box mb={6} />
                            </>
                        }
                        {missingColumns.length === 0 && Object.values(suggestedAccounts).length === 0 &&
                            <>
                                <Box mb={2} />
                                <Grid container justifyContent="space-between" sx={{ width: "800px" }}>
                                    <Button variant="outlined" color="primary" onClick={resetStepper}>
                                        Reiniciar o Fluxo
                                    </Button>
                                    <Button variant="contained" color="primary" onClick={handleProcessFileClick}>
                                        Iniciar o Processamento
                                    </Button>
                                </Grid>
                                <Box mb={6} />
                            </>
                        }
                    </>
            }
        </Grid >
    )
}

export default PreProcessingContent;