import MainLayout from "../../../layouts"
import { Avatar, Box, Button, Divider, Grid, Paper, TextField, Typography } from '@mui/material'
import EditClientModal from "../../../components/Clients/EditClientModal"
import NewProjectModal from "../../../components/Projects/NewProjectModal"
import DeleteClientModal from "../../../components/Clients/DeleteClientModal"
import { useEffect, useRef, useState } from "react"
import { useNavigate, useParams } from "react-router-dom";
import { getCustomerById } from "../../../utils/api"

const loremIpsum = "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."

const ProjectCard = ({ customer, project }) => {
    const navigate = useNavigate();
    const { id, description, name } = project

    const handleClientClick = () => {
        navigate(`/client/${customer.id}/project/${id}`)
    }

    const prepareDescription =
        description.length < 100
            ? description
            : `${description.substring(0, 100)}...`


    return (
        <Grid container item xs={6}>
            <Paper elevation={3} sx={{
                width: "100%", height: "100px", cursor: "pointer", "&:hover": {
                    boxShadow: "0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12)"
                }
            }} onClick={handleClientClick}>
                <Box p={2}>
                    <Grid container direction="column" justifyContent="flex-start" sx={{ height: "100%" }}>
                        <Typography variant="body1"><strong>{name}</strong></Typography>
                        <Typography variant="body1">{prepareDescription}</Typography>
                    </Grid>
                </Box>
            </Paper>
        </Grid >
    )
}

const ClientsViewPage = () => {
    const [showEditingModal, setShowEditingModal] = useState(false)
    const [showAddProjectModal, setShowAddProjectModal] = useState(false)
    const [showDeleteCustomerModal, setShowDeleteCustomerModal] = useState(false)
    const parentRef = useRef(null);
    const [avatarSize, setAvatarSize] = useState(0);
    const { clientId } = useParams();
    const [customer, setCustomer] = useState({ name: "", description: "", projects: [] })

    const handleEditProjectClick = () => {
        setShowEditingModal(true)
    }

    const handleModalClose = () => {
        setShowAddProjectModal(false)
        setShowEditingModal(false)
        fetchData()
    }

    const handleDeleteModalClose = () => {
        setShowDeleteCustomerModal(false)
    }

    const handleAddProjectClick = () => {
        setShowAddProjectModal(true)
    }

    const handleDeleteCustomerClick = () => {
        setShowDeleteCustomerModal(true)
    }

    const fetchData = () => {
        getCustomerById(clientId).then(({ data }) => setCustomer(data)).catch(err => console.log(err))
    }

    useEffect(() => {
        setAvatarSize(parentRef.current.offsetWidth - 48)
        fetchData()
    }, [])

    return (
        <MainLayout>
            {customer ? <>
                <Paper elevation={2} sx={{ width: "100%" }}>
                    <Box px={4} pt={2} pb={8}>
                        <Grid container alignItems="center" justifyContent="space-between" wrap="nowrap">
                            <Typography variant="h5">Cliente</Typography>
                        </Grid>
                        <Box pt={2} mb={6}>
                            <Divider sx={{ width: "calc(100% + 64px)", marginLeft: "-32px" }} />
                        </Box>
                        <Grid container item xs={12}>
                            <Grid container direction="column" item xs={3} alignItems="center" ref={parentRef}>
                                <Avatar src={customer.image_link} variant="rounded" sx={{ width: `${avatarSize}px`, height: `${avatarSize}px`, "& .MuiAvatar-img": { width: "100%", height: "auto" } }} />
                                <Box my={2} />
                                <Grid container item direction="column" justifyContent="space-between">
                                    <Button variant="contained" onClick={handleEditProjectClick}>Editar</Button>
                                    <Box my={1} />
                                    <Button variant="contained" color="error" onClick={handleDeleteCustomerClick}>Excluir</Button>
                                </Grid>
                            </Grid>
                            <Grid container item xs={1} justifyContent="center">
                                <Divider orientation="vertical" flexItem />
                            </Grid>
                            <Grid container item direction="column" xs={8} >
                                <Typography variant="h6"><strong>Nome</strong></Typography>
                                <Typography variant="body1">{customer.name}</Typography>
                                <Box my={1} />
                                <Typography variant="h6"><strong>Descrição</strong></Typography>
                                <Typography variant="body1">{customer.description}</Typography>

                            </Grid>
                            <Grid item xs={4}></Grid>
                            <Grid container item direction="column" xs={8} sx={{ alignSelf: "flex-end" }}>
                                <Box my={1} />
                                <Divider />
                                <Box my={1} />
                                <Grid container justifyContent="space-between" xs={12}>
                                    <Typography variant="h6"><strong>Projetos</strong></Typography>
                                    <Button variant="contained" onClick={handleAddProjectClick}>Adicionar Projeto</Button>
                                </Grid>
                                <Box my={2} />
                                {customer.projects.length > 0 ?
                                    customer.projects.map(project => (
                                        <>
                                            <ProjectCard customer={customer} project={project} />
                                            <Box my={1} />
                                        </>
                                    ))
                                    :
                                    <Grid container justifyContent="center" py={4}>
                                        <Typography variant="body1">Ainda não existem projetos cadastrados.</Typography>
                                    </Grid>
                                }
                            </Grid>
                        </Grid>
                    </Box>
                </Paper>
                <EditClientModal showModal={showEditingModal} handleClose={handleModalClose} customer={customer} />
                <NewProjectModal showModal={showAddProjectModal} handleClose={handleModalClose} customer={customer} />
                <DeleteClientModal showModal={showDeleteCustomerModal} handleClose={handleDeleteModalClose} customer={customer} />
            </>
                : null
            }
        </MainLayout>
    )
}

export default ClientsViewPage