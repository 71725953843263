import React, { useState, useEffect } from "react";
import { getColumnSynonyms as getColumnSynonymsAPI, addColumnSynonym as addColumnSynonymAPI } from "../../utils/api";
import MainLayout from "../../layouts";
import ColumnSynonymsTable from "../../components/ColumnSynonymsTable"
import { TextField, Grid, FormControl, InputLabel, Select, MenuItem, Box, Button } from "@mui/material";
import Modal from '../../components/BaseModal'

function ColumnSynonymsContainer({ projectId }) {
    const [columnSynonyms, setColumnSynonyms] = useState([])
    const [columnSynonym, setColumnSynonym] = useState("")
    const [column, setColumn] = useState("")
    const [showModal, setShowModal] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    const toggleModal = () => {
        setShowModal(!showModal)
    }

    const onInputChange = ({ target }) => {
        const name = target.name
        const value = target.value

        eval(`set${capitalizeFirstLetter(name)}`)(value)
    }

    const addColumnSynonym = async () => {
        const payload = { column_id: column, description: columnSynonym, project_id: projectId }
        try {
            await addColumnSynonymAPI(payload)
        } catch (err) {
            console.log(err)
        }

        toggleModal()

        fetchData()
    }

    async function fetchData() {
        setIsLoading(true)
        getColumnSynonymsAPI(projectId).then(({ data }) => {
            setColumnSynonyms(data)
            setIsLoading(false)
        }
        ).catch(err => {
            console.log(err)
            setIsLoading(false)
        })
    }

    useEffect(() => {
        fetchData()
    }, [])

    return (
        <>
            <ColumnSynonymsTable columnSynonyms={columnSynonyms} fetchData={fetchData} toggleModal={toggleModal} isLoading={isLoading} />
            <Modal open={showModal} onClose={toggleModal} toggleModal={toggleModal} title="Adicionar Sinônimo de Coluna">
                <Grid container direction="column" gap={4} style={{ padding: '40px 0 0 0' }}>
                    <TextField
                        id="name"
                        name="columnSynonym"
                        label="Sinônimo da Coluna"
                        variant="standard"
                        value={columnSynonym}
                        style={{ width: '100%' }}
                        onChange={onInputChange}
                    />
                    <Grid item style={{ flexGrow: 1, flexShrink: 1, minWidth: '200px' }}>
                        <FormControl variant="standard" style={{ width: '100%' }}>
                            <InputLabel id="demo-simple-select-standard-label">Coluna</InputLabel>
                            <Select
                                labelId="demo-simple-select-standard-label"
                                id="demo-simple-select-standard"
                                name="column"
                                value={column}
                                onChange={onInputChange}
                            >
                                <MenuItem value="1">DESCRICAO CONTA RAZAO</MenuItem>
                                <MenuItem value="2">MES</MenuItem>
                                <MenuItem value="3">MONTANTE</MenuItem>
                                <MenuItem value="4">DESCRICAO FORNECEDOR</MenuItem>
                                <MenuItem value="5">CENTRO DE CUSTO</MenuItem>
                                <MenuItem value="6">DESCRICAO CENTRO DE CUSTO</MenuItem>

                            </Select>
                        </FormControl>
                    </Grid>
                    <Box my={1} />
                    <Button variant="contained" disabled={!(column && columnSynonym)} onClick={addColumnSynonym}>Adicionar</Button>
                </Grid>
            </Modal>
        </>
    )
}

export default ColumnSynonymsContainer